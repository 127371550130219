import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { of, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ResponseObject } from '../models/GenericObject';
import { RegexCodes } from '../constants/regexCodes';
import { Media } from '../models/FormControls';
import { ServerError } from '../constants/ServerError';
import moment from 'moment';
import Utils from './Utils';

export class CustomValidators {
  public static passwordMatchValidator(control: AbstractControl): { [key: string]: any } {
    if (!control.get('password').value || !control.get('repeatPassword').value) return null;
    return control.get('password').value !== control.get('repeatPassword').value ? { confPass: true } : null;
  }

  public static required(control: FormControl): { [key: string]: any } {
    return (control.value || control.value === 0) &&
      ((typeof control.value === 'number' && !Number.isNaN(control.value)) ||
        typeof control.value === 'object' ||
        (typeof control.value === 'string' && control.value.trim()))
      ? null
      : { required: true };
  }

  public static mediaRequired(control: FormControl): { [key: string]: any } {
    return control.value && control.value instanceof Media && control.value.url ? null : { required: true };
  }

  public static imageOrVideoRequired(imageKey, videoKey): ValidatorFn {
    return (control: FormGroup): { [key: string]: any } => {
      let required = true;
      const imageControl = control.get(imageKey) as FormControl;
      const videoControl = control.get(videoKey) as FormControl;
      for (const mediaElement of [imageControl, videoControl]) {
        if (mediaElement.value && mediaElement.value instanceof Media && mediaElement.value.url && mediaElement.value.uuid) {
          return null;
        }
      }
      return { required: required };
    };
  }

  public static requiredObject(control: FormControl): { [key: string]: any } {
    return Utils.hasValue(control.value) ? null : { required: true };
  }

  public static objectType(control: FormControl): { [key: string]: any } {
    if (!control.value) {
      return null;
    }
    if (typeof control.value === 'object') {
      const result = !!Object.keys(control.value).length && !!Object.keys(control.value).find((child) => control.value[child]);
      return result ? null : { required: true };
    }
    return { required: true };
  }

  public static minLengthArray(min: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value.length >= min) return null;
      return { required: true };
    };
  }

  public static allowZero(control: FormControl): { [key: string]: any } {
    return control.value !== undefined &&
      (typeof control.value === 'number' || typeof control.value === 'object' || (typeof control.value === 'string' && control.value.trim()))
      ? null
      : { required: true };
  }

  public static valueSelected(array: any[]): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } => {
      return array.find((item) => item.name === c.value?.trim()) ? null : { required: true };
    };
  }

  public static differentThanOldPasswordValidator(control: AbstractControl): { [key: string]: any } {
    if (!control.get('oldPassword').value || !control.get('passwords').get('password').value) return null;
    return control.get('oldPassword').value === control.get('passwords').get('password').value ? { samePass: true } : null;
  }

  public static minLength(value: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } => {
      return (c.value && c.value.trim().length >= value) || !c.value ? null : { minlength: true };
    };
  }

  public static amountRaisedToDateNeededToBeLess(total, rest): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      return control.get(total).value < control.get(total).value - control.get(rest).value ? { greaterThan: true } : null;
    };
  }

  public static maxLength(value: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } => {
      return c.value && c.value.trim().length > value ? { maxlength: true } : null;
    };
  }

  public static password(control: FormControl): { [key: string]: any } {
    const regExp: RegExp = new RegExp(String.raw`(?=.*\d)(?=.*[a-z])(?=.*[A-Z])`);
    return regExp.test(control.value) || !control.value ? null : { password: true };
  }

  public static lettersAndSomeSpecialCharacters(c: FormControl): { [key: string]: any } {
    const regExp: RegExp = /^[^0-9_”„"!¡?÷?¿\/\\+=@#$%ˆ^&*(){}|~<>;:[\]]*$/;
    return regExp.test(c.value) ? null : { noNameFormat: true };
  }

  public static email(c: FormControl): { [key: string]: any } {
    if (!c.value) {
      return null;
    }
    const regExp: RegExp = RegexCodes.email;
    return regExp.test(c.value.trim()) ? null : { email: true };
  }

  public static website(c: FormControl): { [key: string]: any } {
    if (c.value === null || c.value === '' || c.value === undefined) {
      return null;
    }
    const regExp: RegExp =
      /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,15}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    const regExpArray = regExp.exec(c.value);
    return regExpArray && regExpArray[0] === regExpArray.input ? null : { invalidWebsite: true };
  }

  public static validateNameNotTaken(service: any, uuid: string, parent: boolean = false, time: number = 500) {
    return (input: FormControl) => {
      return timer(time).pipe(
        switchMap(() => (parent ? service.checkName(input.value, uuid, parent) : service.checkName(input.value, uuid))),
        map((res: ResponseObject<any>) => {
          return res.response ? { nameExists: true } : null;
        }),
        catchError((err) => {
          if (err.error.message === ServerError.NUMBER_OF_CHARACTERS_NOT_REACHED) {
            return of({ minlength: true });
          } else {
            return null;
          }
        })
      );
    };
  }

  public static endDateAfterStartDate(control: AbstractControl): { [key: string]: any } {
    const startDate = control.get('startDate');
    const endDate = control.get('endDate');
    if (moment(startDate.value).isBefore(endDate.value)) {
      endDate.setErrors(null);
      return null;
    } else {
      endDate.setErrors({ ...endDate.errors, endTime: true });
      return { endTime: true };
    }
  }

  public static fillEntireGroup(group: FormGroup): { [key: string]: any } {
    const name = group.controls['name'];
    const description = group.controls['relationshipDescription'];
    if (name.value?.trim().length > 0 && !description.value?.length) {
      description.setErrors({ required: true });
      return { required: true };
    } else {
      description.setErrors(null);
    }
    if (description.value?.length > 0 && !name.value?.length) {
      name.setErrors({ required: true });
      return { required: true };
    } else {
      name.setErrors(null);
    }
    return null;
  }
  public static hexCode(control: FormControl): { [key: string]: any } {
    const hexRegex = /^#([0-9a-f]{3}){1,2}$/i;
    return hexRegex.test(control.value) ? null : { hexCode: true };
  }
}
