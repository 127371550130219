import { AbstractControl, AsyncValidatorFn, ValidatorFn } from '@angular/forms';
import { FileType } from '../constants/FileType';
import { EntityProfile } from './EntityProfile';
import { GSFormGroup } from './forms/GSFormGroup';
import { GSFormArray } from './forms/GSFormArray';
import { GSFormControl } from './forms/GSFormControl';
import { KeyValue, UUIDName } from './GenericObject';
import { Color } from '@angular-material-components/color-picker';
import { HomepageEntityType, HomepageFilterType } from 'src/app/_core/models/global-settings/HomepageConfig';

export class ControlError {
  constructor(public type: string, public text: string) {}
}

type ParsingFunction = (formControl: AbstractControl, controlKeys) => void;

export const CONTROL_STATE = {
  MANDATORY: 'MANDATORY',
  OPTIONAL: 'OPTIONAL',
  HIDDEN: 'HIDDEN',
};

export interface ControlsObject {
  [key: string]: BasicControlObject;
}

export interface BasicControlObject {
  [key: string]: Control<any>;
}

export interface LanguageResponseFields {
  [key: string]: { [key: string]: any };
}

export enum VALUE_TYPES {
  STRING,
  NUMBER,
  CURRENCY,
  DATE,
  MEDIA,
  NAME_UUID,
  NAME_UUID_LIST,
  ENTITY_UUID,
  SECOND_UUID,
}

export enum CONTROL_TYPES {
  FORM_CONTROL,
  FORM_ARRAY,
  FORM_GROUP,
}

export type GSTypedGroup<T> = {
  [key in keyof T]: GSTypedValue<T[key]>;
};

export type GSTypedArray<T> = T extends UUIDName ? GSFormControl<T[]> : GSTypedValue<T>;

export type GSTypedValue<T> = T extends (infer U)[]
  ? U extends UUIDName
    ? GSFormControl<T>
    : GSFormArray<U>
  : T extends object
  ? T extends TreatAsFormControl
    ? GSFormControl<T>
    : GSFormGroup<T>
  : GSFormControl<T>;

export type TreatAsFormControl = Media | Color | UUIDName | HomepageFilterType | HomepageEntityType | KeyValue;

export class GSControl<T = any> {
  controlType: CONTROL_TYPES;
  controls: { [k: string]: GSControl };
  validators: ValidatorFn[];
  asyncValidators: AsyncValidatorFn[];
  label: string;
  placeholder: string;
  translatable: boolean;
  max: number;
  min: number;
  errors: ControlError[];
  value: T;
  key: string;
  disabled: boolean;
  optionalText: string;
  onAddItem: (value: GSFormGroup<T>) => void;

  constructor(
    options: {
      value?: T;
      key?: string;
      label?: string;
      placeholder?: string;
      max?: number;
      min?: number;
      disabled?: boolean;
      optionalText?: string;
      validators?: ValidatorFn[];
      asyncValidators?: AsyncValidatorFn[];
      errors?: ControlError[];
      controlType?: CONTROL_TYPES;
      translatable?: boolean;
      controls?: { [k: string]: GSControl<any> };
    } = {}
  ) {
    this.label = options.label || '';
    this.placeholder = options.placeholder || '';
    this.value = options.value;
    this.max = options.max;
    this.key = options.key;
    this.min = options.min;
    this.validators = options.validators;
    this.asyncValidators = options.asyncValidators;
    this.errors = options.errors;
    this.controlType = options.controlType || CONTROL_TYPES.FORM_CONTROL;
    this.translatable = options.translatable;
    this.controls = options.controls;
    this.disabled = options.disabled || false;
    this.optionalText = options.optionalText || '';
  }
}

export class Control<T = string> {
  key: string;
  externalKey: string | number;
  profile: EntityProfile;
  label: string;
  placeholder: string;
  optionalText: string;
  maxLength: number;
  minLength: number;
  parent: Control;
  value: T;
  valueType: VALUE_TYPES;
  validators: ValidatorFn[];
  asyncValidators: AsyncValidatorFn[];
  errors: ControlError[];
  order: number;
  controlType: CONTROL_TYPES;
  type: string;
  translatable: boolean;
  profileDisabled: boolean;
  controls: { [k: string]: Control };
  customParsing: ParsingFunction;

  parse(control: AbstractControl, controlKeys): void {
    if (this.customParsing) {
      this.customParsing(control, controlKeys);
    }
  }

  constructor(
    options: {
      value?: T;
      key?: string;
      externalKey?: string | number;
      valueType?: VALUE_TYPES;
      label?: string;
      placeholder?: string;
      optionalText?: string;
      maxLength?: number;
      minLength?: number;
      parent?: Control;
      validators?: ValidatorFn[];
      asyncValidators?: AsyncValidatorFn[];
      errors?: ControlError[];
      controlType?: CONTROL_TYPES;
      translatable?: boolean;
      type?: string;
      order?: number;
      profileDisabled?: boolean;
      controls?: { [k: string]: Control<any> };
      customParsing?: ParsingFunction;
      profile?: EntityProfile;
    } = {}
  ) {
    this.key = options.key || 'item';
    this.externalKey = options.externalKey || '';
    this.valueType = options.valueType || VALUE_TYPES.STRING;
    this.label = options.label || '';
    this.placeholder = options.placeholder || '';
    this.optionalText = options.optionalText || '';
    this.maxLength = options.maxLength || null;
    this.minLength = options.minLength || null;
    this.value = options.value === undefined || options.value === null ? null : options.value;
    this.parent = options.parent || null;
    this.validators = options.validators || [];
    this.asyncValidators = options.asyncValidators;
    this.errors = options.errors;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || CONTROL_TYPES.FORM_CONTROL;
    this.type = options.type || 'text';
    this.controls = options.controls;
    this.translatable = options.translatable !== null && options.translatable !== undefined ? options.translatable : undefined;
    this.profileDisabled = options.profileDisabled || false;
    this.customParsing = options.customParsing;
    this.profile = options.profile;
  }

  get controlsArray(): Control[] {
    return Object.values(this.controls);
  }

  addControl(control: Control): void {
    this.controls = {
      [this.key]: control,
      ...this.controls,
    };
  }

  clone(): Control<T> {
    const controls = {};
    if (!!this.controls) {
      Object.values(this.controls).forEach((ctrl) => Object.assign(controls, { [ctrl.key]: ctrl.clone() }));
    }
    return new Control({
      value: this.value,
      key: this.key,
      externalKey: this.externalKey,
      valueType: this.valueType,
      label: this.label,
      placeholder: this.placeholder,
      optionalText: this.optionalText,
      maxLength: this.maxLength,
      minLength: this.minLength,
      parent: this.parent,
      validators: this.validators,
      asyncValidators: this.asyncValidators,
      errors: [],
      controlType: this.controlType,
      type: this.type,
      order: this.order,
      controls: controls ? controls : null,
      profileDisabled: this.profileDisabled,
      customParsing: this.customParsing,
      profile: {
        uuid: this.profile?.uuid,
        list: this.profile?.list,
        mandatory: this.profile?.mandatory,
        hidden: this.profile?.hidden,
      },
    });
  }

  setProfile(profile: EntityProfile) {
    this.profile = {
      uuid: profile?.uuid,
      list: profile?.list,
      mandatory: profile?.mandatory || false,
      hidden: profile?.hidden || false,
    };
  }
}

export class Media {
  uuid: string;
  fileName: string;
  url: string;
  fileType: FileType;
  allowedFileType: FileType;
  cover: boolean;
  pendingUpload: boolean;
  isFormatValid: boolean;
  progress: number;
  position?: number;

  constructor(fileType: FileType, allowedFileType: FileType) {
    this.fileType = fileType;
    this.allowedFileType = allowedFileType;
    this.uuid = null;
    this.fileName = null;
    this.url = null;
    this.cover = false;
    this.pendingUpload = false;
    this.isFormatValid = true;
    this.progress = 0;
    this.position = null;
  }

  prefillFields(file: any): void {
    this.uuid = file?.uuid;
    this.fileName = file?.filename || file?.fileName;
    this.cover = file?.cover || false;
    this.url = file?.url;
    this.position = file?.position;
  }

  clearAfterDelete(): void {
    this.uuid = null;
    this.fileName = null;
    this.url = null;
    this.cover = false;
    this.pendingUpload = false;
    this.isFormatValid = true;
    this.progress = 0;
    this.position = null;
  }

  setProgress(progress: string) {
    this.progress = +progress;
  }

  resetProgress(): void {
    this.isFormatValid = true;
    this.pendingUpload = false;
    this.progress = 0;
  }

  setFieldsAfterUpload(media: Media, fileName = ''): void {
    this.resetProgress();
    this.fileName = fileName || media.fileName;
    this.uuid = media.uuid;
    this.url = media.url;
  }

  clone(): Media {
    return new Media(this.fileType, this.allowedFileType);
  }
}

export interface InputNumberFormatterOptions {
  allowNegative?: boolean;
  min?: number;
  max?: number;
  allowDecimals?: boolean;
  maxDecimals?: number;
  prefix?: string;
}

export class DocumentMedia extends Media {
  documentName: string;
  ownerName: string;
  ownerUuid: string;
  uploadDate: number;
  ownerProfilePictureUrl: string;

  constructor(fileType: FileType, allowedFileType: FileType) {
    super(fileType, allowedFileType);
    this.ownerName = null;
    this.ownerUuid = null;
    this.uploadDate = null;
    this.ownerProfilePictureUrl = null;
    this.documentName = null;
  }

  override prefillFields(file: any): void {
    super.prefillFields(file);
    this.ownerName = file?.ownerName;
    this.ownerUuid = file?.ownerUuid;
    this.uploadDate = file?.uploadDate;
    this.ownerProfilePictureUrl = file?.ownerProfilePictureUrl;
    this.documentName = file?.documentName;
  }

  override setFieldsAfterUpload(file: DocumentMedia, fileName): void {
    super.setFieldsAfterUpload(file, fileName);
    this.ownerName = file.ownerName;
    this.ownerUuid = file.ownerUuid;
    this.uploadDate = file.uploadDate;
    this.ownerProfilePictureUrl = file.ownerProfilePictureUrl;
  }

  override clone(): DocumentMedia {
    return new DocumentMedia(this.fileType, this.allowedFileType);
  }
}
